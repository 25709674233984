'use strict';
angular.module('portailDepotDemandeAide.depot').controller('depotSimpleRecapitulatifController', [
  '$scope',
  '$log',
  '$q',
  '$translate',
  'controleCompletudeService',
  'aidesService',
  'contributionsService',
  '$state',
  '$stateParams',
  'alertsService',
  '$rootScope',
  'Piece',
  'Link',
  'Aide',
  'contributionsConstants',
  'StoreService',
  'controleEligibiliteService',
  'tiersService',
  'ngToast',
  function (
    $scope,
    $log,
    $q,
    $translate,
    controleCompletudeService,
    aidesService,
    contributionsService,
    $state,
    $stateParams,
    alertsService,
    $rootScope,
    Piece,
    Link,
    Aide,
    contributionsConstants,
    StoreService,
    controleEligibiliteService,
    tiersService,
    ngToast
  ) {
    'use strict';
    //Statuts de contribution
    const statusContributions = contributionsConstants.status;

    // Demandeur steps
    let steps = [];

    $scope.blockingErrors = {
      controleEligibilite: false,
      completude: false,
      datesTeleservice: false,
      global: false,
    };

    $scope.loaded = false;

    let noAttestationProvided = false;

    /**
     *
     */
    function checkBlockingErrors() {
      $scope.navigate.block = false;
      for (const errorKey in $scope.blockingErrors) {
        if ($scope.blockingErrors[errorKey]) $scope.navigate.block = true;
      }
    }

    $scope.$watch('blockingErrors', () => checkBlockingErrors(), true);

    /**
     * Check the completude of the aide
     *
     * @returns {Promise<void>}
     */
    function checkCompletude() {
      if (!$scope.contribution) {
        steps = steps.concat([
          'demandeur-identification',
          'demandeur-adresse',
          'demandeur-representant-legal',
          'demandeur-representants',
          'demandeur-complementaire',
          'demandeur-thematiques',
        ]);

        const depotDelegue =
          _.get($scope.teleserviceConfiguration, 'workflow.depotDelegue.actif') &&
          _.get($scope.aide, 'beneficiaires[0].expand');

        if (depotDelegue) {
          steps = steps.concat([
            'beneficiaire-identification',
            'beneficiaire-adresse',
            'beneficiaire-representant-legal',
            'beneficiaire-representants',
            'beneficiaire-complementaire',
            'beneficiaire-thematiques',
          ]);
        }
      }

      // Aide steps
      steps = steps.concat([
        'informations-generales',
        'pageInformationsGenerales2',
        'pageInformationsGenerales3',
        'document-comptable',
        'domiciliation-bancaire',
      ]);

      // add "indicateurs" if necessary
      if (
        _.get($scope, 'teleserviceConfiguration.workflow.pageIndicateurs.actif', false) &&
        $scope.aide.hierarchies &&
        $scope.aide.hierarchies.length
      ) {
        steps.push('indicateurs');
      }

      // "Pieces" is not present in a contribution (except for redirection contribution)
      if (!$scope.contribution || $scope.contribution.typeContribution === 'REDIRECTION') steps.push('pieces');

      // Controle completude
      $scope.navigate.lockNavigation();
      $scope.controleCompletudeInProgress = true;

      // Call referentiel-financement and concatenate errors
      const completudeRequests = [];
      $scope.completude = {};
      $scope.controlAlerts = [];
      _.each(steps, function (step) {
        completudeRequests.push(
          controleCompletudeService.controlStep($scope.aide, step, $scope.contribution).then(function (result) {
            if (!result.valid) {
              // Sort errors by step
              $scope.completude[step] = result.errors;
              $scope.controlAlerts = $scope.controlAlerts.concat(result.errors);
            }
          })
        );
      });

      return $q.all(completudeRequests).finally(() => {
        $scope.navigate.unlockNavigation();
        $scope.controleCompletudeInProgress = false;

        // If there are errors, we can not transmit 'demande'
        $scope.blockingErrors.completude = $scope.controlAlerts.length > 0;
      });
    }

    /**
     * Edit blocking error dates about the demande state (still open or not)
     */
    function controlDatesTeleservice() {
      // If 'teleservice' is closed,  we can not transmit 'demande'
      $scope.blockingErrors.datesTeleservice = !$scope.teleserviceOpeningData.isOpen;
    }

    /**
     * Fetch signataires and prepare variables using them
     *
     * @returns {Promise}
     */
    function prepareSignataires() {
      return tiersService
        .getSignataires($scope.demandeur.id)
        .then((signataires) => {
          $scope.usersSignataires = signataires.map((signataire) => new Link(signataire));
          // We check if the current user is also a signataire
          $scope.isCurrentUserSignataire = $scope.usersSignataires.some(
            (user) => user.href === _.get($scope, 'currentUser.self')
          );

          $scope.attestationDeclarationHonneur =
            getAttestationDeclarationHonneur() && !$scope.contribution
              ? getAttestationDeclarationHonneur()
              : $scope.attestationDeclarationHonneur;
        })
        .catch(() => {
          $scope.blockingErrors.global = true;
          $scope.$broadcast(
            'alerts',
            alertsService.getAlertError('teleservice.recapitulatif.fetchSignataires.error'),
            'recapitulatif-alerts'
          );
        });
    }

    /**
     * compute noAttestationProvided value
     *
     * @returns {void}
     */
    function computeNoAttestationProvided() {
      noAttestationProvided =
        $scope.declarationCompteSignataire &&
        $scope.usersSignataires.length === 0 &&
        (!$scope.attestationDeclarationHonneur.documents ||
          $scope.attestationDeclarationHonneur.documents.length === 0) &&
        $scope.attempted;

      // if invalid, trigger piece form watcher
      if (noAttestationProvided) {
        $scope.piecesConfiguration.showErrors = true;
      }
    }

    /**
     * Url where to upload files
     *
     * @returns {object|null}
     */
    function getAttestationDeclarationHonneur() {
      computeNoAttestationProvided();
      const attestations = $scope.aide.pieces?.filter(function (piece) {
        return piece.reference === 'attestationDeclarationHonneur';
      });
      return attestations.length > 0 ? attestations[0] : null;
    }

    /**
     * @returns {boolean}
     */
    function isFormValid() {
      // Il y a 4 cas de validation différents
      // 1) cas normal : pas de déclaration de compte signataire, juste l'acceptation cgu
      const case1 = !$scope.declarationCompteSignataire && $scope.cguAcceptation;
      //Ensuite nous avons tous les cas avec déclaration de compte signataire :
      // 2) Déclaration de compte signataire et aucun compte signataire ==> vérification de la présence du document de l'attestation
      const case2 =
        $scope.declarationCompteSignataire &&
        $scope.usersSignataires.length === 0 &&
        !_.isEmpty(_.get($scope, 'attestationDeclarationHonneur.documents'));
      // 3) Déclaration de compte signataire et le user courant est signataire ==> vérification de l'acceptation cgu
      const case3 = $scope.declarationCompteSignataire && $scope.isCurrentUserSignataire && $scope.cguAcceptation;
      // 4) Déclaration de compte signataire et présence de user signataires ==> vérification qu'un user signataire a bien été selectionné
      const case4 =
        $scope.declarationCompteSignataire &&
        $scope.usersSignataires.length > 0 &&
        !$scope.isCurrentUserSignataire &&
        $scope.aide.signataire;
      return case1 || case2 || case3 || case4;
    }

    /**
     *
     * @param {object} aideToUpdate
     */
    function cleanSpecificValues(aideToUpdate) {
      const specifiques = _.get(aideToUpdate, 'specifiques');
      if (specifiques) {
        _.each(specifiques, function (valSpec) {
          if (_.isNull(_.get(valSpec, 'schemaList'))) {
            _.unset(valSpec, 'schemaList');
          }
          if (_.isNull(_.get(valSpec, 'defaultValue'))) {
            _.unset(valSpec, 'defaultValue');
          }
          if (_.isNull(_.get(valSpec, 'subType'))) {
            _.unset(valSpec, 'subType');
          }
          if (_.isNull(_.get(valSpec, 'uniqueKey'))) {
            _.unset(valSpec, 'uniqueKey');
          }
          if (_.isNull(_.get(valSpec, 'schemaList'))) {
            _.unset(valSpec, 'schemaList');
          }
          if (_.isNull(_.get(valSpec, 'restrictions'))) {
            _.unset(valSpec, 'restrictions');
          }
        });
      }
    }

    /**
     * Update demande and contribution
     *
     * @param {object} aideToUpdate
     * @param {string} statutContribution
     * @returns {Promise<void>}
     */
    function updateDemandeAndContribution(aideToUpdate, statutContribution) {
      cleanSpecificValues(aideToUpdate);
      aideToUpdate.demandeur = _.pick(aideToUpdate.demandeur, ['href', 'title', 'rel']);

      return (
        aidesService
          // first check if an avisPrealable is needed (REDIRECTION)
          .determinerAvisPrealable(aideToUpdate, $scope.contribution)
          // update the aide before the contribution
          .then(() => aidesService.update(aideToUpdate, $scope.mdm))
          .then(() => {
            const patches = [
              {
                op: 'add',
                path: '/statut',
                value: statutContribution,
              },
            ];

            if (statutContribution === statusContributions.ANSWERED) {
              patches.push({
                op: 'add',
                path: '/dateReponse',
                value: new Date(),
              });
            }

            return contributionsService.patch($scope.contribution.reference, patches);
          })
          .then((res) => {
            const contribution = res.data;
            $rootScope.$broadcast('refresh-alerteur-contributions', contribution);
            $scope.goToStep('confirmation');
          })
      );
    }

    /**
     * Validate depot
     *
     * @returns {Promise}
     */
    function validateDepot() {
      // Controle completude
      return checkCompletude()
        .then(function () {
          if (!$scope.blockingErrors.completude) {
            $scope.attempted = true;
            computeNoAttestationProvided();

            const hasSelectedSignataire =
              $scope.declarationCompteSignataire &&
              $scope.usersSignataires.length > 0 &&
              !$scope.isCurrentUserSignataire;

            if (isFormValid()) {
              // Dans le cas ou l'on a du selectionner un signataire
              if (hasSelectedSignataire) {
                $scope.aide.status = statusContributions.WAITING_FOR_CERTIFICATE;
              }
              // Dans le cas ou l'utilisateur courant est signataire, on l'enregistre automatiquement comme signataire de la demande
              if ($scope.declarationCompteSignataire && $scope.isCurrentUserSignataire) {
                const linkSignataire = $scope.usersSignataires.find(({ href }) => href === $scope?.currentUser?.self);
                $scope.aide.signataire = new Link(linkSignataire);
              }

              // in the case of a contribution we first update the aide, then the contribution
              if ($scope.contribution) {
                contributionsService.get($scope.contribution.reference, [], ['date']).then((latestContribution) => {
                  // We have to check that the contribution is the latest one to avoid losing the latest modifications
                  if ($scope.contribution.date !== latestContribution.date) {
                    $rootScope.$broadcast(
                      'alerts',
                      alertsService.getAlertError($translate.instant('error.versionObselete'))
                    );

                    $rootScope.$broadcast('depot.conflict');
                    return;
                  }

                  if (hasSelectedSignataire) {
                    return aidesService
                      .getAide($scope.aide.reference)
                      .then(function (oldAide) {
                        oldAide.status = statusContributions.WAITING_FOR_CERTIFICATE;
                        // remove the dateDemande so it is automatically recalculated in the back
                        delete oldAide.dateDemande;
                        oldAide.signataire = new Link($scope.aide.signataire);
                        oldAide = new Aide(oldAide);
                        return updateDemandeAndContribution(oldAide, statusContributions.WAITING_FOR_CERTIFICATE);
                      })
                      .catch((error) => $log.error('(validateDepot/updateDemandeAndContribution)', error));
                  } else {
                    // Do nothing by default
                    let saveContributionPromise = $q.resolve();
                    if (
                      $scope.declarationCompteSignataire &&
                      !_.isEmpty($scope.attestationDeclarationHonneur.documents)
                    ) {
                      setAttestation();
                      // Add the new attestation in the contribution
                      saveContributionPromise = contributionsService.saveContribution($scope.aide, $scope.contribution);
                    }

                    return saveContributionPromise
                      .then(() => {
                        return aidesService
                          .getAideWithContribution($scope.aide.reference, $scope.contribution.reference)
                          .then((aide) => updateDemandeAndContribution(aide, statusContributions.ANSWERED))
                          .then(() => aidesService.updateDemandeurGroupesGestion($scope.aide));
                      })
                      .catch((error) => $log.error('(validateDepot/saveContributionPromise)', error));
                  }
                });
              } else {
                return aidesService
                  .validAide(
                    $scope.demandeur,
                    $scope.aide,
                    isModificationAttestation(),
                    $scope.teleserviceConfiguration,
                    $state.params.tenantId
                  )
                  .then(function (aide) {
                    $log.debug('aide - after creation :' + JSON.stringify(aide));
                    $rootScope.$broadcast('refresh-alerteur-contributions');
                    $rootScope.$broadcast('aide-transmitted', {
                      referenceAdministrative: aide.referenceAdministrative,
                      status: aide.status,
                    });

                    StoreService.currentTiersRef.set($scope.demandeur.reference, true, true);
                    $scope.goToStep('confirmation');
                  })
                  .catch(function (error) {
                    if (error && error.status === 401) {
                      $state.go('home', {
                        loggedOut: true,
                      });
                    }
                    // If the teleservice has been disabled before transmission
                    else if (error.data?.code === '422-34-2') {
                      $scope.blockingErrors.global = true;
                      $scope.$broadcast(
                        'alerts',
                        alertsService.getAlertError('teleservice.recapitulatif.disabled.error'),
                        'recapitulatif-alerts'
                      );
                    }
                  });
              }
            }
          }
        })
        .catch(function (error) {
          // In the case of an 410 error, the demande is deleted,
          // so, we redirect the user on demandes list page
          if (error?.status === 410) {
            // Display toast message to notify the user
            ngToast.create({
              content: $translate.instant('connected.depot.errors.demandeDeleted'),
              className: 'danger',
              timeout: 5000,
              maxNumber: 1,
            });

            const isCurrentRouteSharedAides = $state.current.name.includes('sharedAides');
            $state.go(
              `app.connected.dashboard.aides.demandesAides.${isCurrentRouteSharedAides ? 'sharedAides' : 'aides'}`,
              $stateParams
            );
          } else {
            $scope.blockingErrors.global = true;
            $scope.$broadcast(
              'alerts',
              alertsService.getAlertError('teleservice.recapitulatif.completude.error'),
              'recapitulatif-alerts'
            );
          }
        });
    }

    /**
     * Return step position in workflow
     *
     * @param {object} alert
     * @returns {number}
     */
    $scope.getStepOrder = function (alert) {
      return _.indexOf(steps, alert.step);
    };

    /**
     * Return the translation of a field name or its error
     *
     * @param {string} field field or property name
     * @param {string} label label wanted (ex 'label' or 'error.required')
     * @param {string} step
     * @param {object} labelValue (optional): labels given directly by the completude
     * @param {object} stepMetadata
     * @returns {string}
     */
    $scope.translateField = function (field, label, step, labelValue, stepMetadata) {
      // Default key for fields
      let labelKey = 'teleservice.' + step + '.' + field + '.' + label;

      if (labelValue) {
        // If there is a label given by completude, immediately use it
        if (labelValue.value && label === 'label') {
          return labelValue.value;
        } else if (labelValue.error && label === 'error.invalid') {
          return labelValue.error;
        }
        // The completude might indicates a key to use with $translate
        if (labelValue.key) {
          // If information-generales, it's a specific field
          if (step === 'informations-generales') {
            labelKey = 'presentations.' + labelValue.key + '.' + label;
          }
          // Else, use default key
          else {
            labelKey = 'teleservice.' + step + '.' + labelValue.key + '.' + label;
          }
        }
      }

      // Special cases, because each page has its exceptions

      // For thematiques and complementaire screens, path of fields is given by completude
      if (/(demandeur|beneficiaire)-(thematiques|complementaire)/.test(step)) {
        // However, we have to replace the sections' names: they are slightly different in the portal
        const replacements = [
          { from: 'sectionIdentite', to: 'identite-tiers' },
          { from: 'sectionMoyensHumains', to: 'moyens-humains' },
          { from: 'sectionRelations', to: 'relations-autres-associations' },
          { from: 'effectifsGroupe', to: 'effectifs-groupe' },
          { from: 'donneesFinancieresGroupe', to: 'donneesFinancieres-groupe' },
        ];

        _.each(replacements, function (replacement) {
          labelKey = labelKey.replace(replacement.from, replacement.to);
        });
      }
      // Pieces must use a different message for their required error
      else if ((step === 'pieces' || field === 'rib') && label === 'error.required') {
        if (field === 'piecesObligatoires') {
          labelKey = 'teleservice.pieces.error.missingAssociations';
        } else {
          labelKey = 'teleservice.pieces.error.missing';
        }
      }
      // Domiciliation-bancaire has it's fields under the key "create"
      else if (step === 'domiciliation-bancaire') {
        labelKey =
          'teleservice.domiciliation-bancaire.create.' + (field !== 'rib' ? 'fields.' : '') + field + '.' + label;
      }
      // Document-comptable uses a special label for missing precision on lines
      else if (step === 'document-comptable' && label === 'error.commentaire') {
        labelKey = 'teleservice.document-comptable.precisionObligatoire';
      }

      let translation = $translate.instant(labelKey, stepMetadata);

      // If no translation was found, use default form translation
      if (labelKey === translation) {
        if (label !== 'label') {
          labelKey = 'form.field.' + label;
        }
        translation = $translate.instant(labelKey);

        // If there is still no translation, we display the key that was intented
        if (labelKey === translation) {
          translation = field + '.' + label;
        }
      }

      return translation;
    };
    $scope.headerCompletude = function (alert) {
      return _.indexOf($scope.completude[alert.step], alert) === 0;
    };

    /**
     * Allow to control signataire value and delete if it's not valued
     */
    $scope.onSignataireChange = function () {
      if (_.isEmpty($scope.aide.signataire)) {
        delete $scope.aide.signataire;
      }
    };

    $scope.getStepTitle = function (step) {
      let complement = '';
      if (/-thematiques$/.test(step)) {
        const tiers = /demandeur-thematiques$/.test(step)
          ? $scope.demandeur || {}
          : _.get($scope.aide, 'beneficiaires[0].expand', {});
        complement = '.' + _.get(tiers, 'famille.expand.typeFamille');
      }
      return 'teleservice.' + step + complement + '.title';
    };

    /**
     *
     */
    function removeAttestation() {
      _.remove($scope.aide.pieces, function (piece) {
        return piece.reference === 'attestationDeclarationHonneur';
      });
    }

    /**
     *
     */
    function setAttestation() {
      if (getAttestationDeclarationHonneur()) {
        removeAttestation($scope.attestationDeclarationHonneur);
      }
      $scope.aide.pieces.push(new Piece($scope.attestationDeclarationHonneur));
    }

    $scope.savePersistence = function () {
      if (_.isEmpty($scope.contribution)) {
        setAttestation();
        aidesService.update($scope.aide);
      }
    };

    $scope.changeAcceptation = function (value) {
      $scope.cguAcceptation = value;
    };

    /**
     * @returns {boolean}
     */
    function isModificationAttestation() {
      // On modifie une demande en attestation si son status actuel est REQUESTED et le precedent WAITING_FOR_CERTIFICATE
      const status = _.get($scope, 'aide.history.events');
      return (
        status.length > 1 &&
        status[status.length - 1].reference === statusContributions.REQUESTED &&
        status[status.length - 2].reference === statusContributions.WAITING_FOR_CERTIFICATE
      );
    }

    $scope.redirectionPageControleCompletude = function (step) {
      // Si le tiers existe on redirige vers la fiche récapitulative du Tiers
      if ($scope.tiers && _.startsWith(step, 'demandeur-')) {
        $scope.goToStep('demandeur-recapitulatif', true, true);
      } else {
        $scope.goToStep(step, true, true);
      }
    };

    /**
     * Initialize the controller
     *
     * @returns {void}
     */
    function initialize() {
      // Data for directive download-recapitulatif-aide
      $scope.demandeur = StoreService.demandeur.get();
      $scope.dataRecapitulatifAide = {};
      $scope.dataRecapitulatifAide = angular.copy($scope.aide);

      if ($scope.contribution) {
        $scope.dataRecapitulatifAide.contribution = $scope.contribution.reference;
      }

      if ($scope.declarationCompteSignataire) {
        prepareSignataires();
      }

      $scope.urlDocuments = $scope.aide._links['mgs:documents'].href;
      $scope.persistenceConfiguration = $scope.teleserviceConfiguration?.persistenceConfiguration?.expand ?? {};
      // Directory of file's icons
      $scope.urlFileIcons = './resources/images/file-icons/';

      // Get custom messages to display
      $scope.messageAttestationHonneur =
        $scope.teleserviceConfiguration?.workflow?.pageRecapitulatif?.messageAttestationHonneur;
      $scope.messageSelectionCompteSignataire =
        $scope.teleserviceConfiguration?.workflow?.pageRecapitulatif?.messageSelectionCompteSignataire;
      $scope.messageAide =
        $scope.teleserviceConfiguration?.workflow?.pageRecapitulatif?.selectionCompteSignataire?.help;

      const contenuHtml = $translate.instant(`${$scope.recapitulatifAideConfiguration.ns}.contenuHtml`);
      $scope.testrecaplibelle =
        contenuHtml?.length && contenuHtml !== `${$scope.recapitulatifAideConfiguration.ns}.contenuHtml`;
      $scope.contenuHtml = contenuHtml;
      $scope.cleanNavigate();
      $scope.navigate.noform = true;
      $scope.navigate.ns = $scope.recapitulatifAideConfiguration.ns;

      // check controlesEligibilite if there is an url to call on the teleservice
      if ($scope.teleserviceConfiguration.urlApiExterneControleEligibilite) {
        controleEligibiliteService
          .check($scope.aide)
          // We need to update aide : etag will be different because GET on /controle-eligibilite will update demande date (ROUTE NEEDS TO BE REFACTORED)
          .then((controlesEligibilite) => aidesService.getAide($scope.aide.reference).then(() => controlesEligibilite))
          .then((controlesEligibilite) => {
            $scope.aide.controlesEligibilite = controlesEligibilite;
            const hasError = controlesEligibilite?.some((controle) => controle.blocking);

            $scope.blockingErrors.controleEligibilite = hasError;
          })
          .catch(function () {
            $scope.blockingErrors.global = true;
            $scope.$broadcast(
              'alerts',
              alertsService.getAlertError('teleservice.recapitulatif.controlesEligibilite.error'),
              'recapitulatif-alerts'
            );
          });
      }

      $scope.navigate.validate = validateDepot;

      // Wizard
      $scope.stepsWizard.steps = $scope.getSimpleSteps();
      $scope.stepsWizard.active = 'recapitulatif';

      $scope.viewConfiguration = $scope.recapitulatifAideConfiguration;
      $scope.labelSelectionCompteSignataire =
        $scope.teleserviceConfiguration?.workflow?.pageRecapitulatif?.selectionCompteSignataire?.label;
      $scope.messageErreurSelectionSignataire =
        $scope.teleserviceConfiguration?.workflow?.pageRecapitulatif?.selectionCompteSignataire?.error?.required;

      checkCompletude()
        .then(() => {
          if (!$scope.contribution && !$scope.blockingErrors.completude) {
            return controlDatesTeleservice();
          }
        })
        .catch(() => {
          $scope.blockingErrors.global = true;
          $scope.$broadcast(
            'alerts',
            alertsService.getAlertError('teleservice.recapitulatif.completude.error'),
            'recapitulatif-alerts'
          );
        });

      $scope.loaded = true;
    }

    // ! we wait for pending promises in depot workflow before displaying anything
    this.$onInit = () =>
      StoreService.depot.pendingPromises
        .promise()
        .then(() => initialize())
        .catch((err) => {
          $scope.alerts = alertsService.getAlertError('connected.config.depot.errors.save');
          $log.error(`[depotSimpleRecapitulatifController] StoreService pendingPromises: `, err);
          $scope.loaded = true;
        });
  },
]);
